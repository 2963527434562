import React from 'react'
import Layout from '../components/layout';
import { graphql } from "gatsby"
import { Row, Col } from "react-bootstrap";
import PaperList from "../components/paper-list";
import ProjectList from "../components/project-list";
import SEO from "../components/seo"


export default function Research({data}) {

  console.log(data.published.edges);

    return (
        <Layout>
          <SEO title="Research"></SEO>
          <Row className='mb-3'>
            <Col>
              <h2 className='text-capitilize'>Research</h2>      
            </Col>
          </Row>
          <Row>
            <Col>
              <PaperList titel={'Publications'} papers={data.published.edges}></PaperList>
            </Col>
          </Row>                       
          <Row>
            <Col>
              <PaperList titel={'Working Papers'} papers={data.nonePublished.edges}></PaperList>
            </Col>
          </Row>       
          <Row>
            <Col>
              <ProjectList titel={'Projects'} projects={data.projects.edges}></ProjectList>
            </Col>
          </Row>       
        </Layout>
    )
}

export const query = graphql`
query Papers {
  published: allMarkdownRemark(filter: {frontmatter: {layout: {eq: "paper"}, published: {eq: true}}}) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          published
          submitted,
          jurnal
          date
          coauthor
        }
      }
    }
  },
  nonePublished: allMarkdownRemark(filter:{ frontmatter: { layout: { eq: "paper"}, published: {eq: false}}}) {
    edges { 
    	node {
        id
        fields {slug}
        frontmatter{
          title
          published
          submitted
          jurnal
          coauthor
          date          
        }
      }
    }
  },
  projects: allMarkdownRemark(filter: {frontmatter: {layout: {eq: "project"}}}) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
}
`