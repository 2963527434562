import React from 'react'
import { ListGroup } from "react-bootstrap";
import Paper from "./paper";

const PaperList = ({ papers, titel }) => {
    return (
        <section className="mb-3">
            <h3 style={{ textTransform: 'capitalize'}}>{titel}</h3>            
            <div>
                <ListGroup variant='flush'>
                    {papers.sort((node1, node2) => {
                        return new Date(node2.node.frontmatter.date) - new Date(node1.node.frontmatter.date)
                    }).map(({ node }, index) => (
                        <Paper node={node} key={index} ></Paper>
                    ))
                    }
                </ListGroup>
            </div>
        </section>
    )
}

export default PaperList
