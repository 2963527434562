import React from 'react';

const PublishDate = ({ date }) => {    
    const dateObject = date ? new Date(date) : new Date();
    return (
        <span>
            {dateObject.getFullYear()}
        </span>
    )
}



export default PublishDate;