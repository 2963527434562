import React from 'react'
import { ListGroup } from "react-bootstrap";
import Project from "./project";


const ProjectList = ({projects, titel}) => {
    return (
        <section className="mb-3">            
            <h2 style={{ textTransform: 'capitalize'}}>{titel}</h2>
            <div>
                <ListGroup variant='flush'>
                    {projects.map(({ node }, index) => (
                          <Project node={node} key={index}></Project>
                    ))
                    }
                </ListGroup>
            </div>
        </section>
    )
}

export default ProjectList
