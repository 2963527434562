import React from "react";
import PublishDate from "./publish-date"

const PaperSummary = ({ date, jurnal, coauthor, published }) => {    
    return (
        <ul>
            {
                coauthor ? <Coauthor coauthor={coauthor} /> : null
            }
            {
                jurnal ? <Jurnal jurnal={jurnal} date={date} published={published}/> : null
            }            
        </ul>
    )
}

const Jurnal = ({ jurnal, date, published }) => {
    return (
        <li className="jurnal">
                {jurnal.trim()}{date && published ? "," : null } { date && published ? <PublishDate date={date} /> : null}
        </li>
    )
}


const Coauthor = ({ coauthor }) => {

    function calculateLast(length, index) {
        return length === index + 1;
    }        
    

    return (
        <li>
            (joint with  {
                coauthor.map((author, index) => (
                    <AuthorName author={author} key={index} isLast={calculateLast(coauthor.length, index)}  />
                ))
            })             
        </li>
    )
}

const AuthorName = ({ author, isLast }) => {
    return (
         <span>{author.trim()}{isLast ? "" : "," }</span>
    )   
}

export default PaperSummary;
