import React from 'react'
import { ListGroupItem } from "react-bootstrap";
import { Link } from "gatsby";
import PaperSummary from "./paper-summary";


const Paper = ({node}) => {
    
    const { slug } = node.fields;
    const {title, date, jurnal, published, submitted, coauthor} = node.frontmatter;
    return (
        <ListGroupItem >
            <Link to={slug || "/"}>
                <h4 className='paper-title'>{title}</h4>                
            </Link>
            <section className='paper-summary'>
            {
                published || submitted ? <PaperSummary date={date} jurnal={jurnal} coauthor={coauthor} published={published}></PaperSummary> : null
            }    
            </section>            
        </ListGroupItem>
    )
}

export default Paper
