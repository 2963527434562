import React from 'react'
import { ListGroupItem } from "react-bootstrap";
import { Link } from "gatsby";
const Project = ({ node }) => {
    const { slug } = node.fields;
    const { title } = node.frontmatter;
    return (
        <ListGroupItem>
            {
                slug ?
                    <Link to={slug || "/"}>
                        <h4 className='paper-title'>
                            {title}
                        </h4>
                    </Link>
                    : null
            }
        </ListGroupItem>
    )
}

export default Project
